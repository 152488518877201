@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Poppins:ital,wght@1,200&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

/* #root {
  width: 100%;
  height: 100vh;
} */

/* body {
    background-color: #efefef;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: searchPulse 0.5s ease-in-out;
}

.card-container.search-animation {
  animation: searchPulse 0.5s ease-in-out;
}

.content {
  position: fixed;
  z-index: 20;
  right: 0;
  bottom: 0 ;
  padding: 1em;
}

.banner {
  width: 100vh;
}

.content.pulse-animation {
  animation: pulse 1s 3;
} */

.content img{
  /* width: 60px;
  height: 60px;
  cursor: pointer;
  transition: transform 100ms ease-in-out; */
}

/* .content img:hover{
  transform: scale(1.2);
  margin-bottom: 15px;
}

.Carregadores {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #Fc6b12;
  font-weight: bold;
  font-size: 1.9em;
  display: flex;
  justify-content: center;
  padding: 8px;
}

@media (max-width:600px) {
  .Carregadores {
    font-size: 1.3em;
  }
}

@media (max-width:340px) {
  .Carregadores {
    font-size: 0.8em;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes searchPulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
} */